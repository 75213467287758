import React from 'react'
import './Portfolio.css'
import image1 from '../images/image1.png'
import image2 from '../images/image4.png'
import image3 from '../images/image5.png'
import image4 from '../images/image6.png'
import image5 from '../images/image7.png'
import image6 from '../images/image8.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h4>Recent Work</h4>
      <h1>Portfolio</h1>

      <div className="portfolio-container">
        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image5} alt="" /></div>
          <h3>The Coast</h3>
          <div className="btn-link">
            <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
            
        </article>
        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image2} alt="" /></div>
          <h3>Sample web</h3>
          <div className="btn-link">
           <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
        </article>

        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image3} alt="" /></div>
          <h3>Blogs</h3>
          <div className="btn-link">
           <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
        </article>

        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image4} alt="" /></div>
          <h3>Snake Game</h3>
          <div className="btn-link">
           <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
        </article>

        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image6} alt="" /></div>
          <h3>Youtube.com</h3>
          <div className="btn-link">
           <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
        </article>

        <article className='portfolio-item'>
          <div className="port-item-img"><img src={image1} alt="" /></div>
          <h3>E-Commerce</h3>
          <div className="btn-link">
           <div className="button"><a href="https://github.com" target='blank'>Github</a>
            </div>
            <div className="button"><a href="https://github.com" target='blank'>Live Demo</a>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio